/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/prefer-while */
/* eslint-disable max-len */
import React, { useContext } from 'react';
import { graphql } from 'gatsby';
import Seo from '../components/seo';
import PropTypes from 'prop-types';

import { CITIES_TITLE, CITIES_DESCRIPTION } from '../constants/Languages';
import layoutContext from '../components/layoutContext';

import Sidebar from '../components/Common/Sidebar/Sidebar';
import Cities from '../components/Common/Cities/Cities';
import Slider from '../components/Slider/Slider';
import Voting from '../components/Common/Voting/Voting';

const StockPost = ({ data }) => {
  const { markdownRemark } = data;
  const { frontmatter, html, htmlAst } = markdownRemark;
  const { title, lang, path, headLine, desc, canonicalUrl, disableTitlePostfix, disableDescriptionPostfix } = frontmatter;
  useContext(layoutContext).setCult(lang);

  function* processData(Ast) {
    if (!Ast) {
      return;
    }

    for (let i = 0; i < Ast.length; i++) {
      const node = Ast[i];
      yield node.tagName;

      if (node.children) {
        yield* processData(node.children);
      }
    }
  }

  const it = processData(htmlAst.children);
  let res = it.next();

  for (; ;) {
    if (res.value === 'h1' || res.done) {
      break;
    }
    res = it.next();
  }

  const postfix = disableDescriptionPostfix ? '' : ` ${CITIES_DESCRIPTION[lang].secondPart}`;

  const metaTitle = title ? title :
    `${CITIES_TITLE[lang].firstPart}${headLine}. ${CITIES_TITLE[lang].secondPart}`;
  const metaDescription = desc ? desc :
    `${CITIES_DESCRIPTION[lang].firstPart} ${headLine}${postfix}`;


  return (
    <div>
      <Seo
        title={metaTitle}
        rating={frontmatter.rating ? frontmatter.rating : 4.5}
        path={path}
        lang={lang}
        description={metaDescription}
        canonicalUrl={canonicalUrl}
        disableTitlePostfix={disableTitlePostfix}
      />
      <Slider />
      <div className='article-container'>
        <div className='article'>
          {!!res.value === 'h1' && <h1>{headLine}</h1>}
          <div dangerouslySetInnerHTML={{ __html: html }} />
          <Voting
            rating={frontmatter.rating ? frontmatter.rating : 4.5}
            path={path}
          />
        </div>
        <Sidebar
          stocks news
          articles
        />
      </div>
      <Cities />
    </div>
  );
};

export const query = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      htmlAst
      frontmatter {
        title
        headLine
        path
        category
        lang
        rating
        desc
        canonicalUrl
        disableTitlePostfix
        disableDescriptionPostfix
      }
    }
  }
`;

export default StockPost;


StockPost.propTypes = {
  data: PropTypes.object
};

