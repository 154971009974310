import React, { useContext } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';

import TagPage from '../TagPage/TagPage';
import layoutContext from '../../layoutContext';
import { TEMPLATE_TOKENS } from '../../../constants/Languages';


const Cities = () => {
  const { cult } = useContext(layoutContext);
  return (
    <StaticQuery
      query={graphql`
        query {
          pages: allMarkdownRemark(
            sort: { fields: [frontmatter___date], order: DESC }
            filter: { fileAbsolutePath: { regex: "/content/" } }
          ) {
            totalCount
            edges {
              node {
                frontmatter {
                  headLine
                  path
                  category
                  lang
                }
              }
            }
          }
        }
      `}
      render={data => {
        const { fastCredits } = TEMPLATE_TOKENS[cult];
        const { pages } = data;
        const { edges } = pages;

        const CityPages = edges.reduce((
          pages,
          { node: { frontmatter: { headLine, path, category, lang } } }
        ) => {
          if (lang === cult && category === 'cities') {
            pages.push({ headLine, path });
          }
          return pages;
        }, []);

        return (
          <div className='tag-pages-container'>
            <p className='tag-pages-title'>{fastCredits}</p>
            <div className='tag-pages'>
              {CityPages.map(({ headLine, path }) => (
                <TagPage
                  key={path} path={path}
                  headLine={headLine}
                />
              ))}
            </div>
          </div>
        );
      }}
    />
  );
};

export default Cities;

Cities.propTypes = {
  stocks: PropTypes.bool,
  news: PropTypes.bool,
  articles: PropTypes.bool,
};
